exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-bi-js": () => import("./../../../src/pages/bi.js" /* webpackChunkName: "component---src-pages-bi-js" */),
  "component---src-pages-bigdata-js": () => import("./../../../src/pages/bigdata.js" /* webpackChunkName: "component---src-pages-bigdata-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-member-js": () => import("./../../../src/pages/member.js" /* webpackChunkName: "component---src-pages-member-js" */),
  "component---src-pages-membership-js": () => import("./../../../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-strategy-js": () => import("./../../../src/pages/strategy.js" /* webpackChunkName: "component---src-pages-strategy-js" */)
}

